body {
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
  font-size: 44px;
  line-height: 52px;
  font-weight: 900;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 38px;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  color: grey;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

p {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  color: grey;
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}

a {
  padding-bottom: 5px;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .1);
  -webkit-transition: box-shadow 200ms ease, color 200ms ease;
  transition: box-shadow 200ms ease, color 200ms ease;
  font-family: Roboto, sans-serif;
  color: grey;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

a:hover {
  box-shadow: inset 0 -2px 0 0 #000;
  color: #000;
}

img {
  display: inline-block;
  max-width: 100%;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  margin-top: 5px;
  text-align: center;
}

.hero {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 10vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.hero.pages {
  height: 70vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wrapper-title {
  overflow: hidden;
  width: 100%;
  margin-right: 0vw;
  margin-left: 0vw;
  padding-right: 5vw;
  padding-left: 5vw;
}

.vertical {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 900;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 6vw;
  height: 100vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: none;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-family: Roboto, sans-serif;
  color: #9b9b9b;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.link:hover {
  background-image: none;
}

.link.w--current {
  position: relative;
  text-decoration: line-through;
}

.section {
  position: relative;
  top: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-right: 0vw;
  padding-left: 0vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #dcdee0;
}

.section.main {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.vertical-social {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 30;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10vw;
  height: 100vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-h1 {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: #000;
  font-size: 14vh;
  line-height: 16vh;
  font-weight: 400;
}

.main-h1.bold {
  font-weight: 900;
}

.main-h1.bold.home {
  margin-top: 0px;
  font-size: 22vh;
}

.main-h1.home {
  font-size: 22vh;
  line-height: 22vh;
}

.paragraph {
  width: auto;
  margin-bottom: 30px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: #9b9b9b;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.paragraph.bolder {
  width: 55vw;
  color: #fff;
  font-size: 60px;
  line-height: 70px;
}

.column {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px none #000;
}

.column._100vh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 50%;
  min-height: 100vh;
  padding-right: 10vw;
  padding-left: 15vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: rgba(0, 0, 0, .75);
  color: #fff;
}

.column._100vh:hover {
  background-color: rgba(0, 0, 0, .75);
  opacity: 1;
}

.column._100vh.black {
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #1b1b1b;
}

.column.background-image {
  background-image: url('../images/IMG_20181204_113405.jpg');
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.column.wrapper-img-about {
  width: 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.column.about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 50%;
  height: auto;
  min-height: 100vh;
  padding-left: 15vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: rgba(0, 0, 0, .75);
  color: #fff;
}

.column.about:hover {
  background-color: rgba(0, 0, 0, .75);
  opacity: 1;
}

.column.about.black {
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #1b1b1b;
}

.column.background-image-2 {
  background-image: url('../images/IMG_20181204_113405.jpg');
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.home-subhead {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30vw;
  margin-top: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hr {
  width: 35px;
  height: 1px;
  margin-top: 12px;
  margin-right: 30px;
  background-color: #9b9b9b;
}

.hr.mail {
  margin-top: 30px;
  margin-right: 0px;
  margin-bottom: 50px;
}

.h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  font-size: 38px;
  line-height: 48px;
}

.h2.mail {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
}

.h2.white {
  margin-bottom: 45px;
  color: #fff;
  font-size: 24px;
}

.h2.contact {
  width: 40vw;
  text-align: center;
}

._w-h1 {
  position: relative;
  overflow: hidden;
  padding-right: 10vw;
}

._w-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding-bottom: 2px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom-style: none;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Roboto, sans-serif;
  color: #636363;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

._w-link:hover {
  color: #000;
}

._w-link.social {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
}

.line-hover {
  overflow: hidden;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background-color: #ddd;
}

.project-info {
  position: relative;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.project-info.head {
  left: 0vw;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.icon-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-style: none;
  border-radius: 100px;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: Icomoon;
  color: rgba(0, 0, 0, .3);
  text-decoration: none;
}

.icon-social:hover {
  background-color: #4d4d4d;
  color: #fff;
}

.fill-hover {
  width: 100%;
  height: 2px;
  background-color: #000;
}

.section-2 {
  position: relative;
  top: 70vh;
  width: 100vw;
  height: auto;
  float: none;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
}

.wrapper.contact {
  padding-top: 20vh;
  padding-bottom: 20vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #f8f8f8;
}

.wrapper.center {
  padding-right: 5vw;
  padding-left: 5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wrapper.center.form {
  min-height: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.wrapper.center.form.base {
  padding-bottom: 30vh;
}

.wrapper.center.form.base.hide {
  display: none;
}

.wrapper.about {
  background-image: url('../images/001.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-block-3 {
  height: 100%;
  background-color: transparent;
  background-image: url('../images/001.jpg');
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: scroll;
}

.div-block-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 6vw;
  height: auto;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-right: 5vw;
  padding-left: 5vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._w-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 70vh;
  margin-bottom: 150px;
  padding-right: 5vw;
  padding-left: 5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.wide-background {
  width: 100vw;
  height: 100vh;
  margin-bottom: 10%;
  background-color: #657580;
}

.wide-background._01 {
  background-image: url('../images/5b109a993a8396648e723c7a_screen-001.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.wrapper-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20vh;
  padding-bottom: 20vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-info.about {
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-bottom: 1px none #e4e4e4;
}

.wrapper-info.contact {
  padding-top: 0vh;
  padding-bottom: 0vh;
}

.wrapper-vertical-nav {
  z-index: 900;
  border-right-style: solid;
  border-right-color: #ddd;
}

.light {
  margin-top: 20px;
  font-family: 'Varela Round', sans-serif;
  color: #9b9b9b;
  font-size: 12px;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-list-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-16 {
  margin-bottom: 40px;
}

.wrap-content {
  width: 40vw;
  padding-top: 20px;
  padding-right: 5vw;
}

.wrap-content.links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.field {
  height: 60px;
  margin-bottom: 45px;
  padding-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff #fff #bbb;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  font-size: 22px;
}

.div-block-18 {
  width: 30vw;
  margin-right: 10vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.button {
  padding: 25px 55px;
  background-color: #000;
  -webkit-transition: background-color 400ms ease, box-shadow 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, box-shadow 400ms ease, color 400ms ease;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  font-size: 18px;
}

.button:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 3px #000;
  color: #000;
}

.link-block-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-style: none;
  color: #9b9b9b;
  text-decoration: none;
}

.link-block-2:hover {
  text-decoration: line-through;
}

.link-block-2.w--current {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 2px none #000;
  border-bottom-style: none;
  box-shadow: inset 5px 0 0 0 rgba(250, 25, 25, .5);
  text-decoration: none;
}

.container {
  padding-top: 50px;
}

.body {
  background-color: #f0f0f0;
}

.div-block-20 {
  display: block;
  margin-bottom: 10px;
  padding: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .12);
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.link-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px none #e7e5e5;
  border-radius: 0px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #9b9b9b;
  font-size: 12px;
  text-decoration: none;
}

.link-block-3:hover {
  background-color: #f8f8f8;
  box-shadow: inset 0 0 0 2px #000;
  color: #272727;
}

.image-8 {
  height: 23px;
  margin-bottom: 10px;
}

.heading-6 {
  margin-bottom: 50px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: #c3c3c3;
  font-size: 32px;
  line-height: 66px;
}

.bold {
  color: #161616;
}

.text-block-3 {
  line-height: 16px;
  text-align: center;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.alt-next {
  margin-bottom: 20px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: #9b9b9b;
  font-size: 24px;
  line-height: 60px;
  font-weight: 400;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 20vh;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 5vh 5vw 0vh;
  background-color: transparent;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
  box-shadow: none;
}

.brand:hover {
  border-bottom-style: none;
  box-shadow: none;
}

.brand.w--current {
  box-shadow: none;
}

.brand.w--current:hover {
  box-shadow: none;
}

.link-nav {
  margin-right: 10px;
  margin-left: 10px;
  padding: 0px 0px 5px;
  box-shadow: none;
  -webkit-transition: color 200ms ease, box-shadow 200ms ease;
  transition: color 200ms ease, box-shadow 200ms ease;
  font-family: Roboto, sans-serif;
  color: grey;
  font-size: 16px;
  font-weight: 400;
}

.link-nav:hover {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .5);
  color: #000;
}

.link-nav.w--current {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .5);
  color: #000;
}

.signature {
  margin-top: 50px;
  margin-bottom: 100px;
}

.div-block-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  padding-top: 90px;
  padding-bottom: 90px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-2 {
  display: block;
  height: 60vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.div-block-27 {
  width: 100%;
  height: auto;
  padding-right: 5vw;
  padding-left: 5vw;
  border-top: 1px none #dbdbdb;
  background-color: #fafafa;
}

.small-p {
  color: #b8b8b8;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.logo-footer {
  margin-bottom: 30px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4vh;
  padding-bottom: 2vh;
  float: right;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.arrow {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.number {
  position: relative;
  margin-bottom: 0px;
  margin-left: 0px;
  float: none;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: grey;
  font-size: 70px;
  line-height: 60px;
  font-weight: 400;
}

.big-link {
  margin-bottom: 10px;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  color: #000;
  font-size: 50px;
  line-height: 80px;
}

.proj_body-wrap {
  width: 90vw;
  text-align: center;
}

._w-form {
  min-height: 400px;
}

.proj_body {
  text-align: left;
}

.proj_body h2 {
  display: block;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.proj_body p {
  display: block;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.proj_body h4 {
  display: block;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.proj_body figure {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  clear: none;
}

.proj_body figcaption {
  margin-bottom: 20px;
  color: grey;
}

.next-project {
  padding: 5vw;
}

.project-link {
  box-shadow: inset 0 -2px 0 0 hsla(0, 0%, 100%, .2);
  color: #fff;
}

.project-link:hover {
  box-shadow: inset 0 -2px 0 0 #fff;
  color: #fff;
}

.paragraph-2 {
  margin-bottom: 0px;
}

.project-description {
  color: #7a7a7a;
  font-size: 18px;
}

.arrow-2 {
  margin-bottom: 1px;
  margin-left: 8px;
}

.image-licensing {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .05);
  line-height: 24px;
}

.footer-links {
  margin-right: 20px;
  margin-bottom: 20px;
}

.text-block-4 {
  font-size: 20px;
}

html.w-mod-js *[data-ix="load-up"] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

@media (max-width: 991px) {
  a {
    margin-bottom: 20px;
  }
  .hero {
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .wrapper-title {
    width: 100vw;
  }
  .vertical {
    width: 100vw;
    height: 20vh;
    padding: 0vh 10vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .link {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .section.main {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .main-h1 {
    font-size: 84px;
    line-height: 80px;
  }
  .main-h1.bold {
    font-size: 84px;
    line-height: 80px;
  }
  .main-h1.bold.home {
    line-height: 15vh;
  }
  .main-h1.home {
    font-size: 18vh;
    line-height: 15vh;
  }
  .paragraph.bolder {
    width: auto;
    margin-bottom: 0px;
  }
  .column._100vh {
    width: auto;
    padding-right: 0vw;
    padding-left: 0vw;
  }
  .column._100vh.black {
    padding: 80px 70px;
  }
  .column.wrapper-img-about {
    height: 500px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .column.about {
    width: auto;
    padding-right: 10vw;
    padding-left: 10vw;
  }
  .column.about.black {
    padding: 80px 70px;
  }
  .home-subhead {
    width: 50vw;
  }
  .project-info {
    width: 100%;
    padding: 10vh 5vw;
  }
  .project-info.head {
    margin-bottom: 0px;
    padding-left: 0px;
  }
  .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .wrapper.center.form {
    padding-left: 70px;
  }
  ._w-title {
    margin-bottom: 5vh;
  }
  .wrapper-list-links {
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .wrap-content {
    width: 100%;
  }
  .div-block-18 {
    width: auto;
  }
  .div-block-26 {
    width: auto;
  }
  .div-block-27 {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .big-link {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .hero.pages {
    padding-left: 40px;
    text-align: center;
  }
  .vertical-social {
    position: relative;
    width: 100%;
    height: 30vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: #fff;
  }
  .paragraph.bolder {
    width: auto;
  }
  .column._100vh {
    width: 100%;
    min-height: auto;
  }
  .column._100vh.black {
    padding-right: 40px;
    padding-left: 40px;
  }
  .column.background-image {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .column.about {
    width: 100%;
  }
  .column.about.black {
    padding-right: 40px;
    padding-left: 40px;
  }
  .column.background-image-2 {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .project-info.head {
    padding-left: 0px;
  }
  .wrapper.center {
    padding-right: 40px;
    padding-left: 40px;
  }
  ._w-title {
    margin-bottom: 5vh;
  }
  .wrapper-list-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-20 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar {
    display: block;
    padding-top: 5vh;
    padding-right: 5vw;
    padding-left: 5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .brand {
    height: auto;
  }
  .brand.w--current {
    height: auto;
    margin-bottom: 0px;
  }
  .nav-menu {
    padding-top: 0vh;
    padding-bottom: 0vh;
  }
  .big-link {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 479px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero {
    padding-right: 40px;
    padding-left: 40px;
  }
  .hero.pages {
    text-align: center;
  }
  .vertical-social {
    position: relative;
    left: 0px;
    top: auto;
    right: 0px;
    bottom: 0px;
    height: 60px;
    background-color: #fff;
  }
  .main-h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .main-h1.bold {
    font-size: 60px;
    line-height: 50px;
  }
  .main-h1.bold.home {
    line-height: 8vh;
  }
  .main-h1.home {
    font-size: 8vh;
    line-height: 8vh;
  }
  .paragraph.bolder {
    width: auto;
    font-size: 30px;
    line-height: 40px;
  }
  .column._100vh {
    padding: 60px 40px;
  }
  .column._100vh.black {
    padding: 0vh 0px 0px 0vw;
  }
  .column.background-image {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .column.about {
    padding: 60px 40px;
  }
  .column.about.black {
    padding: 0vh 0px 0px 0vw;
  }
  .column.background-image-2 {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .home-subhead {
    width: auto;
  }
  .h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .h2.mail {
    font-size: 24px;
  }
  .h2.contact {
    width: auto;
  }
  .project-info {
    padding: 0px;
  }
  .project-info.head {
    margin-bottom: 0px;
    padding: 50px 40px;
  }
  .wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .wrapper.center {
    padding-right: 0vw;
    padding-left: 0vw;
  }
  .wrapper.center.form {
    padding-right: 40px;
    padding-left: 40px;
  }
  ._w-title {
    margin-bottom: 5vh;
  }
  .wrapper-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .wrapper-info.about {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .wrapper-info.contact {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .wrap-content {
    width: auto;
  }
  .div-block-18 {
    width: auto;
  }
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .div-block-24 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .heading-6 {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
  }
  .alt-next {
    font-size: 20px;
    line-height: 40px;
  }
  .navbar {
    padding-top: 2vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .brand {
    width: 100%;
    height: 80px;
    padding-left: 0px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .brand.w--current {
    height: 80px;
    padding-left: 0px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .div-block-27 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .nav-menu {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .big-link {
    font-size: 20px;
    line-height: 20px;
  }
}

